// export const baseURL = 'http://192.168.2.105:9001'
// export const baseURL = 'http://192.168.2.131:9001'
// export const baseURL = 'http://139.224.58.168:9008/capitalSupervisionSystem'
// export const baseURL = 'http://tzjg-web.yunqishang.net:18443/capitalSupervisionSystem'
export const baseURL = 'https://szjg-web.yunqishang.net:8443/capitalSupervisionSystem'


export const downLoadBase64 = (imgUrl) => {
    if (window.navigator.msSaveOrOpenBlob) {
        const bstr = atob(imgUrl.split(',')[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        const blob = new Blob([u8arr])
        window.navigator.msSaveOrOpenBlob(blob, 'chart-download' + '.' + 'png')
      } else {
        const a = document.createElement('a')
        a.href = imgUrl
        a.setAttribute('download', 'chart-download')
        a.click()
      }
}
export const orderInfo = (val) => {
  let itemname
  switch (val) {
      case '02':
          itemname = "等待审核"
          break;
      case '03':
          itemname = "审核成功"
          break;
      case '04':
          itemname = "审核拒绝"
          break;
      case '05':
        //   itemname = "回传正数发票"
          itemname = "已开正数发票"
          break;
      case '06':
          itemname = "申请退单"
          break;
      case '07':
          itemname = "退单成功"
          break;
      case '08':
          itemname = "退单失败"
          break;
      case '09':
          itemname = "已传回单"
        //   itemname = "提交退单回执单"
          break;
      case '10':
          itemname = "已开负数发票"
        //   itemname = "回传负数发票"
          break;
      default:
          itemname = "结束交易"
          break;
  }

  return itemname
}
// ## APPID 
// - 所内网站：1，
// - 商家供货商网站：2，
// - 家属app：3，
// - 律师app：4，
// - 所内数字证书类型：5，
// - 律师网页端类型：6，
// - 公司客服类型：9
// - 在押人员: 11