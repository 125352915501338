import axios from 'axios' // 引入axios
import { ElLoading } from 'element-plus';
import { baseURL } from './config';
import router from '../router'
export const http = axios
let loadingInstance
http.interceptors.request.use(
  (config) => {
    config.baseURL = baseURL
    config.timeout = config.timeout ? 300000 : 30 * 1000
    config.headers = {
      'Content-Type': 'application/json',
      Platform: 'COMBAT',
    }
    config.headers.Authorization = window.localStorage.getItem('token')//获取token值
    // if (config.url == '/business/store/detail') {
    //   loadingInstance = null
    // }else{
    //   loadingInstance = ElLoading.service({ fullscreen: true, background: 'rgba(0,0,0,.7)' });
    // }
    return config
  },
  (error) => {
    if (loadingInstance) {
      loadingInstance.close();
    }
    return Promise.reject(error)
  }
)

http.interceptors.response.use(function (response) {
  if (response.headers.authorization) {
    window.localStorage.setItem('token', response.headers.authorization)
  }
  if (loadingInstance) {
    loadingInstance.close();
  }
  if (response.data.status === 200) {
    return response
  } else if (response.data.status === 401) {
    router.replace({
      path: '/login'
    })
    return Promise.reject(response.data.message || response.data.msg)
    // return response
  } else {
    return Promise.reject(response.data.message || response.data.msg)
  }
}, function (error) {
  if (loadingInstance) {
    loadingInstance.close();
  }
  // 对响应错误做点什么
  return Promise.reject(error)
})
